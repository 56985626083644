// const initialState = {
//     cart_count: '',
//     cart_price: ''
// }
export const cart = (state = {}, action) => {
    switch (action.type) {
        case "CART": {
            return {
                count: action.payload.count,
                price: action.payload.price,
                offer: action.payload.group_offer_price || 0,
            }
        }
        default: {
            return state
        }
    }
}
